import React, { useEffect, useState } from 'react';
import { getMetadataUrl } from '../config';

const AudioPlayer = ({ currentStation }) => {
  const [trackInfo, setTrackInfo] = useState('Now Playing: Loading');
  const [playbackError, setPlaybackError] = useState(false); // Track playback errors

  // Fetch metadata periodically
  useEffect(() => {
    if (currentStation) {
      const interval = setInterval(() => {
        const metadataUrl = getMetadataUrl(currentStation.mount);

        fetch(metadataUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            console.log('Full Metadata Response:', data);

            if (Array.isArray(data) && data.length > 0) {
              setTrackInfo(data[0].song || 'No Song Data Available');
            } else {
              setTrackInfo('No Metadata Available');
            }
          })
          .catch((error) => {
            console.error('Error fetching metadata:', error);
            setTrackInfo('Error fetching metadata');
          });
      }, 15000); // Update every 15 seconds

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [currentStation]);

  // Attempt playback and handle errors
  useEffect(() => {
    if (currentStation) {
      const audioElement = document.querySelector('audio');

      if (audioElement) {
        audioElement
          .play()
          .then(() => setPlaybackError(false)) // Clear error if playback succeeds
          .catch((error) => {
            console.error('Audio playback was blocked:', error);
            setPlaybackError(true); // Set error state
          });
      }
    }
  }, [currentStation]);

  return currentStation ? (
    <div className="persistent-player">
      <span>{currentStation.station}</span>
      <span>{trackInfo}</span>
      {playbackError && (
        <div className="error-message">
          Playback was blocked. Please click "Listen Now" again to start playback.
        </div>
      )}
      <audio
        src={`https://listen.181fm.com/${currentStation.mount}_128k.mp3`}
        controls
        autoPlay
      />
    </div>
  ) : null;
};

export default AudioPlayer;

