import React from 'react';
import './Donate.css'; // Add any necessary styles here

const Donate = () => {
  return (
    <div className="donate-page">
      <h2>Support 181.FM and keep your favorite stations alive!</h2>
	<p style={{ color: "yellow", margin: 0 }}>
          <b>
            Please visit our{" "}
            <a
              href="https://www.facebook.com/181.fm"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              181.FM Facebook page
            </a>
            .
          </b>
        </p>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
        style={{ textAlign: "center", margin: "20px 0" }}
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="52JDJYC6E9JX2" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
          border="0"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_US/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>

    </div>
  );
};

export default Donate;
