import React from 'react';
import './Chat.css';

const Chat = () => {
  return (
    <div className="chat-page"
         style={{
           padding: '20px 20px 125px 20px', // Top, Right, Bottom, Left
         }}
      >
      <h1>Chat Room</h1>
	<p>To open chat in a new window <a href="https://www.181.fm/chatpage.html" rarget="_blank">Click here</a></p>
      {/* Embed the chat iframe */}
      <iframe
        title="Chat Room"
        src="https://www.181.fm/chatpage.html"
        width="728"
        height="838"
        frameBorder="0"
        scrolling="auto"
        allowtransparency="true"
        style={{
          border: '1px solid #FFFFFF',
          margin: '20px auto',
          display: 'block',
        }}
      ></iframe>
    </div>
  );
};

export default Chat;

