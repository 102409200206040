import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>About Us</h1>
      <p>
        Welcome to our website! We’re passionate about bringing you the best music and entertainment experience possible. Whether you’re here to explore your favorite genres, discover new stations, or connect with a community of music lovers, we’ve got you covered.
      </p>

      <h2>Our Mission</h2>
      <p>
        Our mission is simple: to provide a platform where music lovers can enjoy seamless access to a wide variety of stations, curated for every taste and mood. We believe music has the power to inspire, connect, and uplift, and we’re here to make it accessible to everyone, everywhere.
      </p>

      <h2>What We Offer</h2>
      <ul>
        <li>
          <strong>Genres Galore:</strong> From timeless oldies to modern dance hits, explore a wide selection of genres to match your mood and style.
        </li>
        <li>
          <strong>Search & Discover:</strong> Our intuitive search feature helps you find exactly what you’re looking for—quickly and easily.
        </li>
        <li>
          <strong>Stream Links:</strong> Enjoy direct access to station streams with clear, easy-to-use links for your convenience.
        </li>
        <li>
          <strong>Recently Played:</strong> Stay updated with the latest songs played on your favorite stations.
        </li>
        <li>
          <strong>Interactive Chat:</strong> Join the conversation in our chat room and connect with other music enthusiasts.
        </li>
      </ul>

      <h2>Why Choose Us?</h2>
      <p>
        We’re more than just a website; we’re a community of music enthusiasts who value quality, variety, and connection. Our user-friendly design, extensive station library, and commitment to excellence set us apart.
      </p>

      <h2>Contact Us</h2>
      <p>
        Have questions, suggestions? feel free to reach out to us via Email:
        <img
          src="/website/images/support.webp"
          alt="Support Email"
          style={{ width: "175px", height: "auto", padding: "0", margin: "0" }}
        />
        .. We're here to help!</p>

      <h2>Thank You</h2>
      <p>
        Thank you for choosing us as your go-to music destination. We’re thrilled to have you here and can’t wait to bring you the ultimate music experience.
      </p>
    </div>
  );
};

export default About;

