import React, { useState } from "react";
import { stations as stationsData } from "../config";

const DynamicStationForm = () => {
  const [selectedStation, setSelectedStation] = useState(null);
  const [embedCode, setEmbedCode] = useState("");
  const [autoplay, setAutoplay] = useState(true);
  const [includeImage, setIncludeImage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  // Flatten stations into a single array with categories
  const allCategories = Object.entries(stationsData);

  const handleStationChange = (event) => {
    const [category, stationMount] = event.target.value.split("|");
    const station = stationsData[category].find((s) => s.mount === stationMount);
    setSelectedStation({ ...station, category });
    generateEmbedCode(station);
  };

  const generateEmbedCode = (station) => {
    const streamUrl = `https://listen.181fm.com/${station.mount}_128k.mp3`;
    const autoplayAttr = autoplay ? "autoplay" : "";
    const imageHtml = includeImage
      ? `<img src="/website/images/station-thumbnails/${station.mount}_300.webp" alt="${station.station} Image" style="width: 300px; height: auto; margin: 10px 0;"/>`
      : "";

    const code = `
      <div style="width: 100%; border: 1px solid #00749C; text-align: center; font-family: Verdana, sans-serif;">
        <br /><strong>${station.station}</strong><br/>
        ${imageHtml}
        <audio controls ${autoplayAttr} style="width: 100%; max-width: 300px;">
          <source src="${streamUrl}" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio><br/>
        <a target="_blank" href="https://www.181.fm/">
          Add '${station.station}' to your page!
        </a><br /><br />
      </div>
    `;
    setEmbedCode(code);
  };

  return (
    <div style={{ padding: "20px 20px 125px 20px", maxWidth: "600px", margin: "0 auto", fontFamily: "Verdana, sans-serif" }}>
      <h3 style={{ textAlign: "center" }}>Customize Your Station Embed</h3>
      <label>
        <b>Select a Station:</b>
        <select
          onChange={handleStationChange}
          style={{
            display: "block",
            width: "100%",
            margin: "10px 0",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        >
          <option value="">-- Select a Station --</option>
          {allCategories.map(([category, stations]) => (
            <optgroup key={category} label={category}>
              {stations.map((station) => (
                <option key={`${category}-${station.mount}`} value={`${category}|${station.mount}`}>
                  {station.station}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </label>

      <label style={{ display: "block", margin: "10px 0" }}>
        <b>Autoplay:</b>
        <input
          type="checkbox"
          checked={autoplay}
          onChange={() => setAutoplay((prev) => !prev)}
          style={{ marginLeft: "10px" }}
        />
      </label>

      <label style={{ display: "block", margin: "10px 0" }}>
        <b>Include Station Image:</b>
        <input
          type="checkbox"
          checked={includeImage}
          onChange={() => setIncludeImage((prev) => !prev)}
          style={{ marginLeft: "10px" }}
        />
      </label>

      {selectedStation && (
        <div style={{ marginTop: "20px" }}>
          <h4>Embed Code for {selectedStation.station}:</h4>
          <textarea
            value={embedCode}
            readOnly
            style={{
              width: "100%",
              height: "150px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontFamily: "monospace",
            }}
          />
          <button
            onClick={() => navigator.clipboard.writeText(embedCode)}
            style={{
              marginTop: "10px",
              display: "block",
              width: "100%",
              padding: "10px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Copy to Clipboard
          </button>
          <button
            onClick={() => setShowPreview((prev) => !prev)}
            style={{
              marginTop: "10px",
              display: "block",
              width: "100%",
              padding: "10px",
              backgroundColor: "#007acc",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {showPreview ? "Hide" : "Show"} Preview
          </button>
        </div>
      )}

      {showPreview && selectedStation && (
        <div style={{ marginTop: "20px", border: "1px solid #ccc", padding: "10px", borderRadius: "4px" }}>
          <h4>Live Preview:</h4>
          <div dangerouslySetInnerHTML={{ __html: embedCode }} />
        </div>
      )}

      {!selectedStation && embedCode && (
        <p style={{ color: "red", textAlign: "center" }}>
          Please select a station to generate an embed code.
        </p>
      )}
    </div>
  );
};

export default DynamicStationForm;

