import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { stations, getImageUrl } from '../config';

const StationList = ({ setCurrentStation }) => {
  const { genre } = useParams(); // Get genre from URL
  const navigate = useNavigate(); // Hook for navigation
  const genreStations = stations[genre] || []; // Filter stations for this genre
  const [isWideView, setIsWideView] = useState(window.innerWidth > 780);

  // Listen for window resize events to toggle between wide/narrow view
  useEffect(() => {
    const handleResize = () => setIsWideView(window.innerWidth > 780);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container">
      {/* Back button is now outside the station grid */}
      <div className="back-button-container">
        <button className="back-button" onClick={() => navigate('/')}>
          Back to Genres
        </button>
      </div>
      <div className="station-grid">
        {genreStations.map((station) => (
          <div key={station.mount} className="station-card">
            <h3>{station.station}</h3>
            <img
              src={getImageUrl(station.mount, station.customImage)}
              alt={station.station}
            />
            <button
              onClick={() => {
                if (isWideView) {
                  // Open in a new window for wide view
                  window.open(
                    `https://player.181fm.com/?station=${station.mount}`,
                    '_blank'
                  );
                } else {
                  // Update audio player for narrow view
                  setCurrentStation(station);
                }
              }}
            >
              Listen Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StationList;

