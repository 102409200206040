import React from 'react';
import { useParams } from 'react-router-dom';

const DynamicPage = () => {
  const { pageName } = useParams();

  return <div>Content for {pageName}</div>;
};

export default DynamicPage;
