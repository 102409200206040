import React from 'react';
import './Support.css';

const Support = () => {
  return (
    <div className="support-page">
      <h1>Support</h1>
      <p>Welcome to the Support page! Here you’ll find information on how to use the features of our website and get the most out of your experience.</p>

      <h2>Getting Started</h2>
      <p>
        Our website is designed to make exploring and listening to music easy and enjoyable. Follow these steps to navigate through the site:
      </p>
      <ol>
        <li>
          <strong>Explore Genres:</strong> The homepage displays a list of music genres. Click on a genre to view the stations available in that category.
        </li>
        <li>
          <strong>Search for Stations:</strong> Use the search bar to find specific stations by name. Simply type a keyword, and matching stations will appear.
        </li>
        <li>
          <strong>Listen to Music:</strong> Click the "Listen Now" button on a station card to start listening. 
          - On desktop, the station will open in a new tab.
          - On mobile, the music will play directly within the audio player at the bottom of the page.
        </li>
      </ol>

      <h2>Features</h2>
      <ul>
        <li>
          <strong>Recent Songs:</strong> Check out the recently played tracks for each station by visiting the "Recently Played" page.
        </li>
        <li>
          <strong>Stream Links:</strong> Visit the "Stream Links" page for a complete list of stations with their stream URLs. You can use this for your own music player setup.
        </li>
        <li>
          <strong>Chat Room:</strong> Connect with other listeners through our interactive chat room. Share your favorite tracks or just say hello!
        </li>
      </ul>

      <h2>Troubleshooting</h2>
      <p>If you encounter any issues while using the website, here are some common solutions:</p>
      <ul>
        <li>
          <strong>Audio Player Not Working:</strong> Ensure your browser supports audio playback. Try refreshing the page or clearing your browser cache.
        </li>
        <li>
          <strong>Search Not Displaying Results:</strong> Check your search term for typos. Only matching stations will be displayed.
        </li>
        <li>
          <strong>Stream Links Not Opening:</strong> Make sure pop-ups are enabled in your browser settings.
        </li>
      </ul>

      <h2>Contact Us</h2>
      <p>If you need further assistance, feel free to reach out to us via Email: 
        <img
          src="/website/images/support.webp"
          alt="Support Email"
          style={{ width: "175px", height: "auto", padding: "0", margin: "0" }}
        />
        .. We're here to help!</p>
    </div>
  );
};

export default Support;

