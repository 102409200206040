import React, { useState } from 'react';
import './SearchBar.css'; // Ensure shared styling is applied

const SearchBar = ({ placeholder, onSearchChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearchChange) {
      onSearchChange(value); // Notify parent component
    }
  };

  const handleClearClick = () => {
    setSearchTerm('');
    if (onSearchChange) {
      onSearchChange(''); // Notify parent component about reset
    }
  };

  return (
    <div className="search-wrapper">
      <input
        type="text"
        className="search-input"
        placeholder={placeholder || 'Search...'}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {searchTerm && (
        <button
          className="clear-button"
          onClick={handleClearClick}
          aria-label="Clear search"
        >
          ✖ Clear Search
        </button>
      )}
    </div>
  );
};

export default SearchBar;

