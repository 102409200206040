import React, { useState } from 'react';
import { stations, getStreamUrl, getImageUrl } from '../config';
import SearchBar from './SearchBar'; // Import reusable SearchBar
import './Links.css'; // General CSS
import './SearchBar.css'; // Import shared Search Style

const Links = () => {
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

 // Helper function to generate the display URL
  const getDisplayStreamUrl = (mount) => `https://listen.181fm.com/${mount}_128k.mp3`;

  // Filter stations based on search term
  const filteredStations = Object.keys(stations).flatMap((genre) =>
    stations[genre]
      .filter((station) => station.station.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((station) => ({ ...station, genre }))
  );

  return (
    <div className="links-page">
      <h1>Stream Links</h1>
      <p>Browse and listen to your favorite stations grouped by genre.</p>

      {/* Use SearchBar with clear button */}
      <SearchBar
        placeholder="Search for a station..."
        onSearchChange={setSearchTerm} // Update search term dynamically
      />

      {/* Render filtered stations */}
      {searchTerm ? (
        <div className="station-list">
          {filteredStations.map((station) => (
            <div key={`${station.genre}-${station.mount}`} className="station-card">
              <img
                src={getImageUrl(station.mount, station.customImage)}
                alt={station.station}
                onError={(e) => (e.target.style.display = 'none')}
              />
              <div className="station-info">
                <h3 className="station-name">{station.station}</h3>
                <p className="stream-url">
                  <strong>Stream URL:</strong><br /> {getDisplayStreamUrl(station.mount)}
                </p>
                <button
                  className="listen-now-button"
                  onClick={() => window.open(getStreamUrl(station.mount, station.customUrl), '_blank')}
                >
                  Listen Now
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="genre-section-container">
          {Object.keys(stations).map((genre) => (
            <div key={genre} className="genre-section">
              <h2 className="genre-title">{genre}</h2>
              <div className="station-list">
                {stations[genre].map((station) => (
                  <div key={`${genre}-${station.mount}`} className="station-card">
                    <img
                      src={getImageUrl(station.mount, station.customImage)}
                      alt={station.station}
                      onError={(e) => (e.target.style.display = 'none')}
                    />
                    <div className="station-info">
                      <h3>{station.station}</h3>
                      <p>{getDisplayStreamUrl(station.mount)}</p>
                      <button
                        onClick={() => window.open(getStreamUrl(station.mount, station.customUrl), '_blank')}
                      >
                        Listen Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Links;

