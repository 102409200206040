import React, { useState, useEffect, useRef } from 'react';
import { stations } from '../config';
import { useNavigate } from 'react-router-dom';

const GenreStationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false); // Track dropdown visibility
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Reference for the dropdown container

  // Close dropdown if a click is detected outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    // Attach event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Generate dropdown items dynamically
  const renderOptions = () => {
    return Object.keys(stations).map((genre) => (
      <React.Fragment key={genre}>
        <div className="dropdown-genre">{genre}</div>
        {stations[genre] && stations[genre].length > 0 ? (
          stations[genre].map((station) => (
            <div
              key={station.mount}
              className="dropdown-item"
              onClick={() => handleSelect(station.station, station.mount)}
            >
              -- {station.station}
            </div>
          ))
        ) : (
          <div className="dropdown-no-items">No stations available</div>
        )}
      </React.Fragment>
    ));
  };

  // Handle option selection
  const handleSelect = (stationName, mount) => {
    setIsOpen(false); // Close dropdown
    navigate(`/recent?station=${encodeURIComponent(stationName)}&mount=${encodeURIComponent(mount)}`);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button
        className="dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        Check Recently Played
      </button>
      {isOpen && <div className="dropdown-menu">{renderOptions()}</div>}
    </div>
  );
};

export default GenreStationDropdown;

