import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Recent.css';

const Recent = () => {
  const [searchParams] = useSearchParams();
  const stationDisplayName = searchParams.get('station');
  const stationMountName = searchParams.get('mount');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (stationDisplayName && stationMountName) {
      fetch(`/website/json.php?d=${encodeURIComponent(stationDisplayName)}&s=${encodeURIComponent(stationMountName)}`)
        .then((response) => {
          if (!response.ok) throw new Error('Error fetching data');
          return response.json();
        })
        .then((json) => {
          console.log('Fetched Data:', json); // Log the fetched data structure
          setData(json);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err.message);
          setError(err.message);
          setLoading(false);
        });
    }
  }, [stationDisplayName, stationMountName]);

  if (!stationDisplayName || !stationMountName) {
    return <p>Error: Missing station or mount information.</p>;
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Convert data object to an array, excluding non-numeric keys
  const songs = Object.keys(data)
    .filter((key) => !isNaN(key)) // Include only numeric keys
    .map((key) => data[key]); // Map numeric keys to their values

  return (
    <div className="recent-songs-page">
      <h1>Recent Song list for <br/>{data.station_display_name}</h1>
      <ul>
        {songs.map((song, index) => (
          <li key={index}>
            <strong>{song.song}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Recent;

