import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <a
            href="https://play.google.com/store/apps/details?id=usa.app.listenfm"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
           <img src="/website/images/app/googlebadge.webp" width="100px" alt="Download Listen.FM Android App" />
          </a>
          <a
            href="https://apps.apple.com/us/app/listen-fm/id1505746371"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
           <img src="/website/images/app/applebadge.webp" width="100px" alt="Download Listen.FM iPhone App" />
          </a>
          <a
            href="https://www.facebook.com/181.fm"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
           <img src="/website/images/app/facebook.webp" width="100px" height="29.66px" alt="Follow our 181.FM Facebook Page" />
          </a>
          <a
            href="https://www.instagram.com/181.fm"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
           <img src="/website/images/app/instagram.webp" width="100px" height="29.66px" alt="Follow our 181.FM Instagram Page"  />
          </a>
          <a
            href="https://www.microsoft.com/store/apps/9P9XVV7N54B3"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
           <img src="/website/images/app/windowsbadge.webp" width="100px" alt="Download Listen.FM Windows App" />
          </a>
        </div>
        <p className="footer-text">© 2024 181.FM. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

