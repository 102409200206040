import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { genres, stations, getImageUrl } from '../config';
import SearchBar from './SearchBar'; // Import reusable SearchBar
import './GenreList.css'; // General CSS
import './SearchBar.css'; // Import shared Search Style

const GenreList = ({ setCurrentStation }) => {
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  // Flatten stations for easier searching
  const allStations = Object.entries(stations).flatMap(([genre, genreStations]) =>
    genreStations.map((station) => ({ ...station, genre }))
  );

  // Filter genres and stations based on search term
  const filteredStations = allStations.filter(
    (station) =>
      station.station.toLowerCase().includes(searchTerm.toLowerCase()) || // Match station name
      station.genre.toLowerCase().includes(searchTerm.toLowerCase())     // Match genre name
  );

  const isSearching = searchTerm.trim().length > 0;

  return (
    <div className="genre-list-page">
      <h1><center>Browse Genres or Search Stations</center></h1>

      {/* Use SearchBar with clear button */}
      <SearchBar
        placeholder="Search stations..."
        onSearchChange={setSearchTerm} // Update search term dynamically
      />

      {/* Render genres or filtered stations */}
      {!isSearching ? (
        <div className="genre-grid">
          {Object.keys(genres).map((genreKey) => (
            <Link to={`/stations/${genreKey}`} key={genreKey}>
              <div className="genre-card">
                <img
                  src={genres[genreKey].img}
                  alt={genres[genreKey].name}
                  onError={(e) => (e.target.style.display = 'none')}
                />
                <h3>{genres[genreKey].name}</h3>
                <p>{genres[genreKey].description}</p>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="station-grid">
          {filteredStations.length > 0 ? (
            filteredStations.map((station) => (
              <div
                key={`${station.mount}-${station.genre}`} // Unique key
                className="station-card"
              >
                <img
                  src={getImageUrl(station.mount, station.customImage)}
                  alt={station.station}
                  onError={(e) => (e.target.style.display = 'none')}
                />
                <h3>{station.station}</h3>
                <p>Genre: {station.genre}</p>
                <button onClick={() => setCurrentStation(station)}>Listen Now</button>
              </div>
            ))
          ) : (
            <p>No stations found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default GenreList;

