/** menu **/
export const menuLinks = [
  { name: "Home", file: "home.html", id: "home" },
  { name: "Genres", file: "genres.html", id: "genres" },
  { name: "Stations", file: "stations.html", id: "stations" },
  { name: "About", file: "about.html", id: "about" },
  { name: "Contact", file: "contact.html", id: "contact" },
  { name: "Privacy", file: "privacy.html", id: "privacy" },
];


/* Genres List */
export const genres = {
  "80's Decade": {
    name: "80's Decade",
    img: "/website/images/genre-thumbnails/80s_Decade_300.webp",
    description: "Relive the iconic hits and unforgettable moments of the 80s."
  },
  "90's Decade": {
    name: "90's Decade",
    img: "/website/images/genre-thumbnails/90s_Decade_300.webp",
    description: "Explore the chart-topping hits, and unforgettable cultural vibes that defined the spirit of the 90s."
  },
  Christmas: {
    name: "Christmas",
    img: "/website/images/genre-thumbnails/Christmas_300.webp",
    description: "Celebrate the season with timeless holiday classics and new favorites."
  },
  Comedy: {
    name: "Comedy",
    img: "/website/images/genre-thumbnails/Comedy2_300.webp",
    description: "Laugh out loud with stand-up, skits, and comedic legends."
  },
  Country: {
    name: "Country",
    img: "/website/images/genre-thumbnails/Country_300.webp",
    description: "Feel the heart of America with country hits and classics."
  },
  Dance: {
    name: "Dance",
    img: "/website/images/genre-thumbnails/Dance_300.webp",
    description: "Get into the groove with beats to move your body and soul."
  },
  "Easy Listening": {
    name: "Easy Listening",
    img: "/website/images/genre-thumbnails/EasyListening_300.webp",
    description: "Relax and unwind with soothing and melodic tunes."
  },
  Oldies: {
    name: "Oldies",
    img: "/website/images/genre-thumbnails/Oldies_300.webp",
    description: "Step back in time with golden oldies from decades past."
  },
  Pop: {
    name: "Pop",
    img: "/website/images/genre-thumbnails/Pop_300.webp",
    description: "Stay on trend with the biggest chart-toppers and pop classics."
  },
  Rock: {
    name: "Rock",
    img: "/website/images/genre-thumbnails/Rock_300.webp",
    description: "Rock your world with classic and modern hits from the best bands."
  },
  Urban: {
    name: "Urban",
    img: "/website/images/genre-thumbnails/Urban_300.webp",
    description: "Discover the rhythm of the streets with R&B, hip-hop, and more."
  },
  // Add more genres as needed
};

/* Stations List */
/*
Usage:
Custom URL and Custom Image is there and used ONLY if they are added.
Examples:
    { name: "Beatles", mount: "181-beatles" },
    { name: "Motown", mount: "181-motown", customImage: "/website/images/custom/motown-special.webp" },
    { name: "Classic Rock", mount: "181-classicrock" },
    { name: "Hard Rock", mount: "181-hardrock", customUrl: "https://custom-stream/hardrock.mp3" },
If there is a custom image needing to be used or URL you use it like the above examples. otherwise 
it is not included in the JSON.
*/
export const stations = {
"Oldies": [
 { station: "Classic Hits 181", mount: "181-greatoldies", customImage: null, customUrl: null },
 { station: "Good Time Oldies", mount: "181-goodtime", customImage: null, customUrl: null },
 { station: "Mellow Gold", mount: "181-mellow", customImage: null, customUrl: null },
 { station: "Soul", mount: "181-soul", customImage: null, customUrl: null },
 { station: "Beatles", mount: "181-beatles", customImage: null, customUrl: null },
 { station: "Super 70s", mount: "181-70s", customImage: null, customUrl: null },
],
"80's Decade": [
 { station: "Awesome 80's", mount: "181-awesome80s", customImage: null, customUrl: null },
 { station: "Lite 80's", mount: "181-lite80s", customImage: null, customUrl: null },
 { station: "80's Country", mount: "181-80scountry", customImage: null, customUrl: null },
 { station: "80's Lite RnB", mount: "181-80sliternb", customImage: null, customUrl: null },
 { station: "80's RnB", mount: "181-80srnb", customImage: null, customUrl: null },
 { station: "80's Hairband", mount: "181-hairband", customImage: null, customUrl: null },
],
"90's Decade": [
 { station: "Lite 90's", mount: "181-lite90s", customImage: null, customUrl: null },
 { station: "90's Lite RnB", mount: "181-90sliternb", customImage: null, customUrl: null },
 { station: "90's Alternative", mount: "181-90salt", customImage: null, customUrl: null },
 { station: "90's RnB", mount: "181-90srnb", customImage: null, customUrl: null },
 { station: "Star 90's", mount: "181-star90s", customImage: null, customUrl: null },
 { station: "90's Country", mount: "181-90scountry", customImage: null, customUrl: null },
 { station: "90's Dance", mount: "181-90sdance", customImage: null, customUrl: null },
],
"Pop": [
 { station: "Power 181 (Top 40)", mount: "181-power", customImage: null, customUrl: null },
 { station: "Power 181 [E]", mount: "181-powerexplicit", customImage: null, customUrl: null },
 { station: "The Office", mount: "181-office", customImage: null, customUrl: null },
 { station: "The Mix", mount: "181-themix", customImage: null, customUrl: null },
 { station: "The Point", mount: "181-thepoint", customImage: null, customUrl: null },
 { station: "Old School HipHop/RnB", mount: "181-oldschool", customImage: null, customUrl: null },
 { station: "Smooth AC", mount: "181-smoothac", customImage: null, customUrl: null },
 { station: "UK top 40", mount: "181-uktop40", customImage: null, customUrl: null },
 { station: "The Heart (Love Songs)", mount: "181-heart", customImage: null, customUrl: null },
 { station: "The Vibe of Vegas", mount: "181-vibe", customImage: null, customUrl: null },
],
"Rock": [
 { station: "The Buzz (Alt. Rock)", mount: "181-buzz", customImage: null, customUrl: null },
 { station: "Classic Buzz (Alt)", mount: "181-classicbuzz", customImage: null, customUrl: null },
 { station: "The Eagle (Classic)", mount: "181-eagle", customImage: null, customUrl: null },
 { station: "Rock 181", mount: "181-rock", customImage: null, customUrl: null },
 { station: "Rock 40 (Rock & Roll)", mount: "181-rock40", customImage: null, customUrl: null },
 { station: "80's Hairband", mount: "181-hairband", customImage: null, customUrl: null },
 { station: "Yacht Rock", mount: "181-yachtrock", customImage: null, customUrl: null },
 { station: "Chloe @181.FM", mount: "181-chloe", customImage: null, customUrl: null },
 { station: "The Point", mount: "181-thepoint", customImage: null, customUrl: null },
 { station: "The Rock! (Hard Rock)", mount: "181-hardrock", customImage: null, customUrl: null },
],
"Country": [
 { station: "Kickin' Country", mount: "181-kickincountry", customImage: null, customUrl: null },
 { station: "Real Country", mount: "181-realcountry", customImage: null, customUrl: null },
 { station: "Highway 181", mount: "181-highway", customImage: null, customUrl: null },
 { station: "80's Country", mount: "181-80scountry", customImage: null, customUrl: null },
 { station: "90's Country", mount: "181-90scountry", customImage: null, customUrl: null },
 { station: "Front Porch (Bluegrass)", mount: "181-frontporch", customImage: null, customUrl: null },
],
"Urban": [
 { station: "The Beat (HipHop/R&B)", mount: "181-beat", customImage: null, customUrl: null },
 { station: "Classic R&B", mount: "181-classicrnb", customImage: null, customUrl: null },
 { station: "The Box (Urban)", mount: "181-thebox", customImage: null, customUrl: null },
 { station: "True R&B", mount: "181-rnb", customImage: null, customUrl: null },
 { station: "Soul", mount: "181-soul", customImage: null, customUrl: null },
 { station: "Party 181", mount: "181-party", customImage: null, customUrl: null },
 { station: "Jammin 181", mount: "181-jammin", customImage: null, customUrl: null },
 { station: "80's RnB", mount: "181-80srnb", customImage: null, customUrl: null },
 { station: "80's Lite RnB", mount: "181-80sliternb", customImage: null, customUrl: null },
 { station: "Old School HipHop/RnB", mount: "181-oldschool", customImage: null, customUrl: null },
],
"Dance": [
 { station: "Energy 98", mount: "181-energy98", customImage: null, customUrl: null },
 { station: "Classic Energy", mount: "181-classicenergy", customImage: null, customUrl: null },
 { station: "Chilled Out", mount: "181-chilled", customImage: null, customUrl: null },
 { station: "Energy 93", mount: "181-energy93", customImage: null, customUrl: null },
 { station: "Studio 181", mount: "181-ball", customImage: null, customUrl: null },
 { station: "The Vibe of Vegas", mount: "181-vibe", customImage: null, customUrl: null },
 { station: "90's Dance", mount: "181-90sdance", customImage: null, customUrl: null },
 { station: "Techno Club", mount: "181-techno", customImage: null, customUrl: null },
],
"Easy Listening": [
 { station: "True Blues", mount: "181-blues", customImage: null, customUrl: null },
 { station: "Jazz Mix", mount: "181-jazzmix", customImage: null, customUrl: null },
 { station: "The Breeze", mount: "181-breeze", customImage: null, customUrl: null },
 { station: "Classical Guitar", mount: "181-classicalguitar", customImage: null, customUrl: null },
 { station: "Classical Jazz", mount: "181-classicaljazz", customImage: null, customUrl: null },
 { station: "Vocal Jazz", mount: "181-vocals", customImage: null, customUrl: null },
 { station: "BeBop Jazz", mount: "181-bebop", customImage: null, customUrl: null },
 { station: "Fusion Jazz", mount: "181-fusionjazz", customImage: null, customUrl: null },
 { station: "Trance Jazz", mount: "181-trancejazz", customImage: null, customUrl: null },
 { station: "Classical Music", mount: "181-classical", customImage: null, customUrl: null },
 { station: "Acid Jazz", mount: "181-acidjazz", customImage: null, customUrl: null },
],
"Comedy": [
 { station: "Comedy", mount: "181-comedy", customImage: null, customUrl: null },
],
"Christmas": [
 { station: "Christmas Kountry", mount: "181-xkkountry", customImage: null, customUrl: null },
 { station: "Christmas Classics", mount: "181-xtraditional", customImage: null, customUrl: null },
 { station: "Christmas Oldies", mount: "181-xoldies", customImage: null, customUrl: null },
 { station: "Christmas Country", mount: "181-xcountry", customImage: null, customUrl: null },
 { station: "Christmas Power", mount: "181-xpower", customImage: null, customUrl: null },
 { station: "Christmas Highway", mount: "181-xhighway", customImage: null, customUrl: null },
 { station: "Christmas Soundtracks", mount: "181-xsoundtrax", customImage: null, customUrl: null },
 { station: "Christmas R&B", mount: "181-xtrue", customImage: null, customUrl: null },
 { station: "Christmas Rock", mount: "181-xrock", customImage: null, customUrl: null },
 { station: "Christmas Mix", mount: "181-xmix", customImage: null, customUrl: null },
 { station: "Christmas Fun", mount: "181-xfun", customImage: null, customUrl: null },
 { station: "Christmas Kids", mount: "181-xkids", customImage: null, customUrl: null },
 { station: "Christmas Blender", mount: "181-xblender", customImage: null, customUrl: null },
 { station: "Christmas Standards", mount: "181-xstandards", customImage: null, customUrl: null },
 { station: "Christmas Spirit", mount: "181-xspirit", customImage: null, customUrl: null },
 { station: "Christmas Smooth Jazz", mount: "181-xsmooth", customImage: null, customUrl: null },
 { station: "Christmas Gospel", mount: "181-xgospel", customImage: null, customUrl: null },
 { station: "Christmas Swing", mount: "181-xswing", customImage: null, customUrl: null },
]
};

export function getImageUrl(mount, customImage = null) {
  return customImage || `/website/images/station-thumbnails/${mount}_300.webp`;
}

export function getStreamUrl(mount, customUrl = null) {
  //direct listen link
  //return customUrl || `https://listen.181fm.com/${mount}_128k.mp3`;
  //direct player link
  return customUrl || `https://player.181fm.com/?station=${mount}`;
}

export function getMetadataUrl(mount) {
  return `/website/get.php?s=${mount}`; 
   /* return `../get.php?s=${mount}`; */
}
