import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GenreStationDropdown from './GenreStationDropdown';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Track if menu is open
  const [subMenuOpen, setSubMenuOpen] = useState(false); // Track if sub-menu is open

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log('Menu open:', menuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
    console.log('Sub-menu open:', subMenuOpen);
  };

  return (
    <header>
      <div className="logo-container">
        <Link to="/">
          <img src="/website/images/181_logo_300.webp" alt="181.FM Logo" className="logo" />
        </Link>
        <div className="genre-dropdown-wrapper">
          {/* Dropdown under the logo */}
          <GenreStationDropdown />
        </div>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
        <ul className={`menu ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="https://player.181fm.com" target="_blank">181.FM Player</Link></li>
          <li><Link to="https://www.181.fm/pwa" target="_blank">Mobile Web App</Link></li>
          <li><Link to="/links">Stream Links</Link></li>
          <li><Link to="/codes">Radio Codes</Link></li>
          <li className="dropdown">
            <button className="submenu-toggle" onClick={toggleSubMenu}>More</button>
            <ul className={`submenu ${subMenuOpen ? 'open' : ''}`}>
              <li><Link to="https://www.facebook.com/181.fm" target="_blank">Facebook</Link></li>
              <li><Link to="https://www.instagram.com/181.fm" target="_blank">Instagram</Link></li>
              <li><Link to="https://apps.apple.com/us/app/listen-fm/id1505746371" target="_blank">iPhone App</Link></li>
              <li><Link to="https://play.google.com/store/apps/details?id=usa.app.listenfm" target="_blank">Android App</Link></li>
              <li><Link to="https://www.microsoft.com/store/apps/9P9XVV7N54B3" target="_blank">Windows App</Link></li>
              <li><Link to="/chat">Chat</Link></li>
              <li><Link to="/donate">Donate</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/support">181.FM Support</Link></li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

