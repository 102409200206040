import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './styles/style.css';
import './styles/theme.css';

import Header from './components/Header';
import Footer from './components/Footer';

import GenreList from './components/GenreList';
import StationList from './components/StationList';
import AudioPlayer from './components/AudioPlayer';

import Donate from './components/Donate';
import About from './components/About';
import Recent from './components/Recent';
import Links from './components/Links';
import Chat from './components/Chat';
import Codes from './components/Codes';
import Support from './components/Support';
import DynamicPage from './components/DynamicPage';

function App() {
  const [currentStation, setCurrentStation] = useState(null);
  const NotFound = () => (
    <h1 style={{ textAlign: "center" }}>404 - Page Not Found</h1>
  );

  return (
    <Router basename="/website">
     <div className="app">
      <Header />
      <AudioPlayer currentStation={currentStation} />
      <main>
      <Routes>
        <Route path="/" element={<GenreList setCurrentStation={setCurrentStation} />} />
        {/* Redirect /index.html to the root */}
        <Route path="index.html" element={<Navigate to="/" replace />} />
        <Route path="/stations/:genre" element={<StationList setCurrentStation={setCurrentStation} />} />
        <Route path="/support" element={<Support />} />
        <Route path="/links" element={<Links />} />
        <Route path="/about" element={<About />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/codes" element={<Codes />} />
        <Route path="/recent" element={<Recent />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/page/:pageName" element={<DynamicPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </main>
      <Footer />
     </div>
    </Router>
  );
}

export default App;

